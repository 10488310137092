import React, { useEffect, useState } from "react";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import axios from "axios";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import CleanButton from "assets/cleanBtn.svg";

const ToolInput = ({
  handleClick,
  result,
  isButtonDisabled,
  ResetButton,
  loading,
  handleReset,
  setText,
  buttontext,
  setOutputText,
  setInputLang,
  setOutputLang,
  setTranslateToInput,
  setTranslateFromInput,
  setAnalysisResult,
  setShowOutput,
  setWordCount,
  lang,
  text,
  wordCount,
  iscleanBtn = false,
  children,
  divHeight,
  placeholder,
  handleClearText,
  isDifficulty = false,
  isGuidance = false,
  isContextual=false,
  setDivHeight,
  isTranslation,
  refDiv,
}) => {
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  useEffect(() => {
    const wordLength = (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [text, setWordCount]);

  const handleInputTextChange = (e) => {
    const newText = e.target.value;

    setText(newText);
  };

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    // if (extension) return;
    // setFile(selectedFile);
    if (!extension) {
      setUploadError(true)
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      // alert.error(translatedData?.toast_text);
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    // console.log(formData.get("file"), "Uploaded file");

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);


      setText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };

  const uploadFileTools = isDifficulty;

  return (
    <div
      ref={refDiv}
      className={`relative ${
        isGuidance ? "w-full lg:w-[30%]" : "w-full"
      } w-full p-3 bg-white rounded-lg lg:rounded-l-lg shadow-lg lg:shadow-none border-none mt-4 lg:mt-0`}
    >
      {children}
      {fileUploadLoading ? (
        <FileLoadingInput file={file} isGuidance={isGuidance} divHeight={divHeight} isLgScreen={isLgScreen}/>
      ) : sizeLimitError && !fileUploadLoading ? (
        <ReUploadFile
          file={file}
          setFile={setFile}
          setSizeLImitError={setSizeLImitError}
          uploadFile={uploadFile}
        />
      ) : uploadError && !fileUploadLoading ? (
        <UnsupportedFile
          file={file}
          setSizeLImitError={setSizeLImitError}
          setFile={setFile}
          uploadFile={uploadFile}
          setFileUploadError={setUploadError}
        />
      ) : (
        <textarea
          style={{
            minHeight: isLgScreen
              ? isGuidance
                ? `183px`
                : divHeight
                ? `${divHeight}px`
                : `230px`
              : "230px",
          }}
          id="input-textarea"
          dir={lang === "fa" || lang === "he" || lang === "ar" ? "rtl" : "ltr"}
          className={`anonymize_tool font-opensans placeholder-[#B4B4B4] text-black leading-[26px]  ${isContextual ? "pr-8" : ""} py-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
          placeholder={placeholder}
          value={text}
          onChange={handleInputTextChange}
        />
      )}
      <div className="flex justify-between items-center py-4">
        {text ? (
          <p className="text-right mt-2 text-sm text-[#9C9C9C]">
             {wordCount} words
          </p>
        ) : (
          <>
            <label htmlFor={`uploadFile ${isTranslation}`} className="flex gap-2 cursor-pointer">
              <img src={UploadIcon} alt="upload_icon" />
              <span>Upload File</span>
            </label>
            <input
              type="file"
              className="hidden"
              id={`uploadFile ${isTranslation}`}
              onChange={uploadFile}
            />
          </>
        )}
      </div>
      {isGuidance && (
        <div className="flex gap-1">
          <button
            onClick={handleClick}
            disabled={!!result}
            className={`${
              loading
                ? "bg-[#E8E8E8] text-[#858585]"
                : result
                ? "bg-[#E8E8E8] text-[#858585]"
                : "bg-[#5B93FF] text-white"
            }  ${
              isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : ""
            }   text-base font-opensans text-center rounded-md px-5 py-3 w-full `}
          >
            {buttontext}
          </button>

          <button
            disabled={!result}
            className={`  ${result ? "cursor-pointer" : "cursor-not-allowed"}`}
            onClick={handleReset}
          >
            <img src={ResetButton} alt="reset" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ToolInput;
