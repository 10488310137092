import React from 'react'

const ToolsBanner = ({ text, title, description }) => {
  return (
    <>
      <h1 className="text-center text-[#5B93FF] font-primary uppercase font-base font-semibold mb-2.5">
        {text}
      </h1>
      <h2 className="text-[28px] md:text-[32px] text-center font-primary leading-[48px] md:leading-[57px] font-bold ">
        {title}
      </h2>
      {/* <p className="hidden md:block mt-6 text-lg text-center font-opensans leading-[26px]">
        {description}
      </p> */}
    </>
  );
};

export default ToolsBanner