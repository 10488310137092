import ToolInput from 'components/commontools/InsertTool'
import ToolOutput from 'components/commontools/ToolOutput'
import ToolsBanner from 'components/commontools/toolsBanner'
import Layout from 'components/layout'
import QualityDifficultyOutput from 'components/QualityTools/Qualitydifficult'
import SEO from 'components/seo'
import ToolsBreadcrumb from 'components/ToolsBredcrumb'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { useLocation } from '@reach/router'
import { endpoints } from 'utils/endpoints'
import http from 'utils/http'
import { parseResult } from 'utils/toolsresult'
import Shareicon from "assets/shareicon.svg"
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from 'components/QualityTools/ToolFaq'
import { ContextualToolFaqData } from 'components/FAQ/config'

const Index = () => {
    const [divHeight, setDivHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showOutput, setShowOutput] = useState(false);
  
    const [transtalteFromInput, setTranslateFromInput] = useState("");
    const [inputText, setInputText] = useState("");
    const refDiv = useRef(null);
    const refcontext = useRef(null)
    const outputRef = useRef(null);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [wordCountInput, setWordCountInput] = useState(0);
    const [shareId, setShareId] = useState("");
    const [copySuccess, setCopySuccess] = useState("Copy");
    const [selectedFormat, setSelectedFormat] = useState("CSV");
    const [isLgScreen, setIsLgScreen] = useState(false);
    useEffect(() => {
      if (typeof window !== "undefined") {
        setIsLgScreen(window.innerWidth > 1024);
        const handleResize = () => {
          setIsLgScreen(window.innerWidth > 1024);
        };
  
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }
    }, []);
    const handleClick = async () => {
      if (isButtonDisabled) return;
  
      setLoading(true);
      setError(false);
      setShowOutput(false);
  
      try {
        const response = await http().post(endpoints.forms.aiTools, {
          tool_name: "contextual_glossary_generator",
          user_text: inputText,
          // source_language_code: inputLang,
        });

        const newShareId = response?.meta?.share_id;
  
        setShareId(newShareId);
  
        setShowOutput(true);
  
        window.history.pushState(
          {},
          "",
          `/tools/contextual-glossary-generator?share-id=${newShareId}`
        );
         window.location.reload();
      } catch (error) {
        console.error("Error during quality analysis:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const UpdatreshareId = params.get("share-id");
    
    const url = location.href;

    const displayShareId = shareId ? shareId : UpdatreshareId;
    useEffect(() => {
      if (!displayShareId) return;
      const fatchAnalysis = async () => {
        setLoading(true);
        try {
          const response = await http().get(
            `${endpoints.forms.aiTools}/${displayShareId}`
          );
          setAnalysisResult(response);
          setShowOutput(true);
          const { user_text: sourceText} =
            response.meta;
  
          setInputText(sourceText);
         
        } catch (err) {
          setError(err.message || "An unexpected error occurred.");
        } finally {
          setLoading(false);
        }
      };
      fatchAnalysis();
    }, [displayShareId]);
    const resultValue = parseResult(analysisResult?.result);

    // const glossary = resultValue?.glossary;
    const glossaryEntries = resultValue 
    ? Object.entries(resultValue ).map(([term, definition]) => ({
        Term: term,
        Definition: definition,
      }))
    : [];
    // console.log(glossary,"glossary")

    const isButtonDisabled = !inputText;
    useEffect(() => {
      if (refDiv?.current) {
        setDivHeight(refDiv?.current?.base?.offsetHeight);
      }
    }, [refDiv, analysisResult]);
    const handleClearText = () => {

        setInputText("");
    
   
        setTranslateFromInput("");
        setAnalysisResult(null);
        setShowOutput(false);
        setWordCountInput(0);
        setError("")
        navigate(`/tools/contextual-glossary-generator/`);
  
      };

      const copyText = () => {
        if (outputRef.current) {
          const textToCopy = outputRef.current.innerText;
          navigator.clipboard.writeText(textToCopy);
          setCopySuccess("Copied");
          setTimeout(() => {
            setCopySuccess("Copy");
          }, 1500);
        }
      };
      const copyUrl = () => {
        navigator.clipboard.writeText(url);
        setCopySuccess("Link Copied");
          setTimeout(() => {
            setCopySuccess("Copy");
          }, 1500);
      };


  const downloadOutputFormat = ["CSV", "XLS"];

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
  };

    
      const downloadCSV = () => {
   
        if (!glossaryEntries || !Array.isArray(glossaryEntries)) return;
      
        const csvRows = [];

        csvRows.push(["Term", "Definition"]);

        glossaryEntries.forEach((item) => {
          csvRows.push([item.Term, item.Definition]);
        });
  
        const csvString = csvRows.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "glossary.csv"; 
        a.click();
        URL.revokeObjectURL(url); 
      };
      const downloadXLS = () => {
        if (!glossaryEntries || !Array.isArray(glossaryEntries)) return;

        let xlsContent = "<table><thead><tr><th>Term</th><th>Definition</th></tr></thead><tbody>";
        
        glossaryEntries.forEach((item) => {
          xlsContent += `<tr><td>${item.Term}</td><td>${item.Definition}</td></tr>`;
        });
      
        xlsContent += "</tbody></table>";
        const blob = new Blob([xlsContent], { type: "application/vnd.ms-excel" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "glossary.xls";
        a.click();
        URL.revokeObjectURL(url); 
      };
      const handleDownload = () => {
       
        if (selectedFormat === "CSV") {
          downloadCSV();
        } else if (selectedFormat === "XLS") {
          downloadXLS();
        }
      };
      useEffect(() => {
        if (refcontext?.current) {
          setDivHeight(refcontext?.current?.offsetHeight);
        }
      }, [refcontext, analysisResult]);
      console.log(divHeight,"divheight")
       return (
        <Layout>
            <SEO title="Contextual Glossary Generator​"
                description="Create a tailored glossary with the Contextual Glossary Generator. Analyze any text to find key terms and definitions for an industry-specific reference guide."
                slug="/tools/contextual-glossary-generator" />

            <div className="bg-[#F5F5F5]">
                <div className="w-full  py-7 ">
                    <ToolsBreadcrumb />
                </div>
                <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Contextual Glossary Generator"
            title="Easily Extract Key Terms and Definitions from Any Text"
            description="The Contextual Glossary Generator quickly identifies and defines key terms from your text, making it easy to build a specialized glossary for any document. Just upload content in any language, and let the tool do the rest."
          />
      
           <div className="lg:flex gap-3 h-full mt-12">
            <div className={`flex   flex-col gap-3 ${analysisResult ? `w-full  lg:w-[50%]` : "w-full  lg:w-[65%]"} `}>
              <div className="lg:flex items-center bg-white  h-full rounded-lg shadow-lg ">
                <ToolInput
                
                
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                //   setOutputText={setOutputText}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  handleClearText={handleClearText}
                  placeholder="Enter your text here"
                  isContextual = {true}
                >
                  
                  {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleClearText}
                          className="cursor-pointer  absolute right-[32px] top-8"
                        />
                  )}
                    </ToolInput>
               
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              result={analysisResult}
              isButtonDisabled={isButtonDisabled}
    
              loading={loading}
      
              showOutput={showOutput}
              error={error}
              
              refvalue={refcontext}
              divHeight={divHeight}
              isContextual={true}
              buttontext="Generate Glossary"
              UpdatreshareId={UpdatreshareId}
              text="Your results will appear here. <br /> Please enter your texts and click 'Generate Glossary' to begin."
            >
              <ul
                style={{ maxHeight: isLgScreen ? `${divHeight - 22}px` : "" }}
                className=" w-full text-sm text-left rtl:text-right relative overflow-y-auto  shadow-md sm:rounded-lg"
                ref={outputRef}
              >
               
               <li className="flex">
            <p className="px-6 py-3 bg-[#BDD3FF] w-full lg:w-[30%] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            Key Term
            </p>
            <p className="px-6 py-3 bg-[#E0EBFF] w-full lg:w-[70%] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            Definition
            </p>
          </li>
                <div className="mt-2 grid gap-2">
                  {glossaryEntries &&
                    glossaryEntries?.map((item, index) => (
                      
                      <li className="flex" key={index}>
                        <p className="p-3 w-full  lg:w-[30%] text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                          {item.Term}
                        </p>
                        <p className="p-3 bg-[#F0F5FF] w-full  lg:w-[70%] border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                          {item.Definition}
                        </p>
                      </li>
                    ))}
                </div>
               
              </ul>
              <div className="lg:absolute lg:bottom-3 lg:left-0 w-full bg-[#ffffff] mt-6 py-4 px-3 rounded-lg flex items-center justify-end gap-5">
                <div className="flex gap-4 items-center justify-between  ">
                  <button
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copySuccess}
                    onClick={copyText}
                    className=""
                  >
                    <img src={CopyIcon} alt="/copyicons" />
                  </button>
                  <button    data-tooltip-id="copylink-tooltip"
                    // data-tooltip-content="Copy Link"
                    data-tooltip-content={copySuccess}
                    onClick={copyUrl}
                    className="">
                  <img src={Shareicon} alt="/share" />
                  </button>
              
                   <div   data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download" className='flex border border-[#5B93FF] rounded-md gap-2 items-center p-2'>
                   <button
                  
                    onClick={handleDownload}
                    className=""
                  >
                    <img src={DownloadIcon} alt="/downloadicon" />
                  </button>
                    <select value={selectedFormat} onChange={handleFormatChange} className='!text-[#5B93FF] !outline-none !border-none'>
                       {downloadOutputFormat.map((format, index) => (
                      <option key={index} value={format}>
                     {format}
                   </option>
                    ))}
                </select>
              
                </div>
                </div>
                <Tooltip
                  id="copy-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
                <Tooltip
                  id="copylink-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
                <Tooltip
                  id="download-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
              </div>
            </ToolOutput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          {analysisResult && (
            <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
              <div>
                <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                  Need help translating?
                </h2>
                <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                  If your translation needs improvement, reach out to Tomedes
                  for expert translation, editing, and proofreading services.
                </p>
              </div>
              <Link to="/contactus.php">
                <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                  Contact us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          )}
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
          The Contextual Glossary Generator quickly identifies and defines key terms from your text, making it easy to build a specialized glossary for any document. Just upload content in any language, and let the tool do the rest.
          </p>
          </div>
            </div>
            <ToolFaq QaToolFaqData={ContextualToolFaqData} />
        </Layout>
    )
}

export default Index
