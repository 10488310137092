import Loader from "components/loader";
import React from "react";
import Vector from "assets/checkquality.svg";

const ToolOutput = ({
  handleClick,
  result,
  isButtonDisabled,
  ResetButton,
  loading,
  handleReset,
  showOutput,
  error,
  children,
  isRefreshBtn,
  buttontext,
  text,
  isDifficulty = false,
  divHeight,
  isContextual=false,
  UpdatreshareId,
  refvalue,
 
}) => {

  return (
    <div ref={refvalue}
    
    className={`relative ${
      isDifficulty
        ? "w-full lg:w-[40%]"
        : isContextual
        ? result 
          ? `w-full lg:w-[50%] pb-[90px] `
          : "w-full lg:w-[35%]" 
        : "w-full lg:w-[30%]"
    } bg-white p-3 mt-8 lg:mt-0 flex flex-col gap-3`}
    >
      <div className="flex gap-1">
        <button
          onClick={handleClick}
          disabled={!!result}
          className={`${
            loading
              ? "bg-[#E8E8E8] text-[#858585]"
              : showOutput
              ? "bg-[#E8E8E8] text-[#858585]"
              : "bg-[#5B93FF] text-white"
          } ${
            isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : ""
          } text-base font-opensans text-center rounded-md px-5 py-3 w-full`}
        >
          {buttontext}
        </button>

        {/* <button
          disabled={!result}
          className={`  ${result ? "cursor-pointer" : "cursor-not-allowed"}`}
          onClick={handleReset}
        >
          {!isRefreshBtn && !isDifficulty && (
            <img src={ResetButton} alt="reset" />
          )}
        </button> */}
      </div>

   
           



      <div
        id="toolOutput"
        className={`flex flex-col  h-full ${
          showOutput
            ? "items-start justify-start"
            : "items-center justify-center"
        }`}
      >
       {
  loading ? (
    <div>
      <Loader />
      <p className="text-center font-opensans leading-6 text-sm mt-4">
        We're analyzing the translation and source text.
        <br />
        Please wait a few seconds for the results.
      </p>
    </div>
  ) : result === "null" ? ( 
    <div className="flex flex-col items-center justify-center font-opensans text-[#FF6961] leading-6 text-sm text-red-500">
      <svg
        className="h-8 w-8 text-red-500"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
        <line x1="12" y1="9" x2="12" y2="13" />
        <line x1="12" y1="17" x2="12.01" y2="17" />
      </svg>
      <p className="text-center">
        Oops! We couldn't generate the glossary this time. Please check
        your input and try again.
      </p>
    </div>
  ) : error ? (
    <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
      {error?.message}
    </span>
  ) : showOutput ? (  
    children
  ) : (
    <>
      <img src={Vector} alt="vector" />
      <p
        className="text-center font-opensans leading-6 text-sm"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </>
  )
}

      </div>
    
    </div>
  );
};

export default ToolOutput;
